.App {
  text-align: center;
  background-color: #626262;
  display: flex;
  flex-direction: column;
  min-width: 100vw;
  min-height: 100vh;
}

.App-header {
  display: flex;
  flex: .25;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.App-header2 {
  display: flex;
  flex: .25;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 6vmin);
  color: white;
}

.App-link {
  color: #ffffff;
  padding-left: 10px;
  padding-right: 10px;
}
